import i18n from '@/i18n'

export default [
  {
    path: '/login',
    meta: {
      name: 'External',
      title: i18n.global.t('Core.Login.Title'),
      requiresAuth: false,
      requiresRole: false,
      props: route => ({ query: route.query.tenant }),
    },
    component: () => import(`@/views/ExternalView.vue`),
    children: [
      {
        path: '',
        name: 'login',
        component: () => import(`@/views/LoginView.vue`),
      },
    ],
  },
  {
    path: '/:area/abschlussarbeit/confirm/:code',
    meta: {
      name: 'Abschlussarbeit',
      title: i18n.global.t('Common.ReferatBestaetigungTitel'),
      requiresAuth: false,
      requiresRole: false,
      props: true,
    },
    component: () =>
      import(`@/views/Bestaetigung/ReferatBestaetigungCardView.vue`),
  },
  {
    path: '/',
    meta: {
      name: 'dashboard-view',
      requiresAuth: true,
      requiresRole: false,
    },
    component: () => import(`@/views/DashboardLayout.vue`),
    children: [
      {
        path: '',
        meta: {
          name: 'Landingpage',
          requiresAuth: true,
          requiresRole: false,
        },
        component: () => import(`@/views/DashboardViews/LandingpageLayout.vue`),
      },
      {
        path: 'user',
        meta: {
          name: 'UserProfile',
          title: i18n.global.t('Common.Profile'),
          requiresAuth: true,
        },
        component: () => import(`@/views/UserViews/UserProfileView.vue`),
      },
      {
        path: 'exams',
        meta: {
          name: 'MyExamsCard',
          title: i18n.global.t('Common.PruefungenTitel'),
          requiresAuth: true,
        },
        component: () => import(`@/views/StudentViews/ExamsCardView.vue`),
      },
      {
        path: 'mystundenplan',
        meta: {
          name: 'MyStundenplan',
          title: i18n.global.t('Common.MeinStundenplanTitel'),
          requiresAuth: true,
        },
        component: () => import(`@/views/StudentViews/MyStundenplanView.vue`),
      },
      {
        path: 'notifications',
        meta: {
          name: 'Notifications',
          title: i18n.global.t('Common.NotificationsTitel'),
          requiresAuth: true,
        },
        component: () => import(`@/views/DashboardViews/NotificationsView.vue`),
      },
      {
        path: 'bps-apply',
        meta: {
          name: 'BPS-Verwaltung',
          title: i18n.global.t('Common.BPSPortalTitel'),
          requiresAuth: true,
        },
        component: () => import(`@/views/DashboardViews/BPSStudCardView.vue`),
      },
      {
        path: 'thesis-apply',
        meta: {
          name: 'Abschlussarbeit',
          requiresAuth: true,
          title: i18n.global.t('Common.AnmeldungAbschlussarbeitPOTitel'),
        },
        component: () => import(`@/views/StudentViews/ThesisStudListView.vue`),
      },
      {
        path: 'thesis-overview',
        meta: {
          name: 'Abschlussarbeit',
          title: i18n.global.t('Common.AnmeldungAbschlussarbeitTitel'),
          requiresAuth: true,
        },
        component: () => import (`@/views/DashboardViews/ThesisPrueferView.vue`),
      },
      {
        path: 'bewerbungen/:id',
        meta: {
          name: 'Bewerbungen',
          title: i18n.global.t('Common.BPSManagerListTitel'),
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/DashboardViews/BPSManagerportalCardView.vue`),
      },
      {
        path: 'zulassungen/:id',
        meta: {
          name: 'Bewerbungen',
          title: i18n.global.t('Common.BPSZulassungTitel'),
          requiresAuth: true,
        },
        component: () =>
          import(
            `@/views/DashboardViews/BPSManagerportalZulassungenCardView.vue`
          ),
      },
      {
        path: 'bewerbungen/:id/pruefer',
        meta: {
          name: 'Prüferliste',
          title: i18n.global.t('Common.BPSPrueferTitel'),
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/DashboardViews/BPSPrueferCardView.vue`),
        props: true,
      },
      {
        path: 'bewerbungen/:id/pruefungstermine',
        meta: {
          name: 'Prüfungstermine',
          title: i18n.global.t('Common.BPSTerminTitel'),
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/DashboardViews/BPSTermineCardView.vue`),
        props: true,
      },
      {
        path: 'applications-card',
        meta: {
          name: 'Applications',
          title: i18n.global.t('Common.PruefungsausschussantraegeTitel'),
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/StudentViews/ApplicationsCardView.vue`),
      },

      {
        path: 'prueferportal',
        meta: {
          name: 'Prüferportal',
          title: i18n.global.t('Common.PrueferportalTitel'),
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/PrueferViews/PrueferportalCardView.vue`),
      },
      {
        path: 'moduladmin/po-list/',
        meta: {
          name: 'Prüfungsordnungen',
          title: i18n.global.t('Common.POListeTitel'),
          requiresAuth: true,
        },
        component: () => import(`@/views/ModulManagement/POListView.vue`),
      },

      {
        path: 'moduladmin/po_new_version/:id/:prozessTypName/',
        meta: {
          name: 'PODaten & Modulliste (new version)',
          title: i18n.global.t('Common.PODetailTitle'),
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/ModulManagement/PODetailView.vue`),
        props: true,
      },
      {
        path: 'moduladmin/po-list/:id/',
        meta: {
          name: 'PODaten & Modulliste',
          title: i18n.global.t('Common.PODetailTitle'),
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/ModulManagement/PODetailView.vue`),
        props: true,
      },
      {
        path: 'moduladmin/po-list/:poId/modul-list/',
        meta: {
          name: 'Moduls',
          title: i18n.global.t('Common.ModulListTitle'),
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/ModulManagement/ModulListView.vue`),
        props: true,
      },
      {
        path: 'modul-list/',
        meta: {
          name: 'ModulUnitList',
          title: i18n.global.t('Common.ModulUnitTitel'),
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/ModulManagement/ModulUnitListView.vue`),
        props: true,
      },
      {
        path: '/moduls/:id/',
        meta: {
          name: 'ModulDetail',
          title: i18n.global.t('Common.ModulDetailTitle'),
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/ModulManagement/ModulDetailView.vue`),
        props: true,
      },
      {
        path: '/moduls/:modulId/versionsvergleich/:toModulId/',
        meta: {
          name: 'Versionsvergleich',
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/ModulManagement/DiffView.vue`),
        props: true,
      },
      {
        path: 'clearingadmin/clearingeintrag-list/',
        meta: {
          name: 'Clearingeinträge',
          title: i18n.global.t('Common.ClearingadministrationTitel'),
          requiresAuth: true,
        },
        component: () => import(`@/views/ModulManagement/ClearingeintragListView.vue`),
      },
      {
        path: 'clearingadmin/po-list/:poId/modul-list/',
        meta: {
          name: 'Moduls for Clearingadmin',
          title: i18n.global.t('Common.ModulListCearingTitel'),
          requiresAuth: true,
          noScroll: true,
        },
        component: () =>
          import(`@/views/ModulManagement/ModulListClearingAdminView.vue`),
        props: true,
      },
      {
        path: 'moduladmin/dokumentvorlagen/',
        meta: {
          name: 'Dokumentvorlagengruppen',
          title: i18n.global.t('Common.DokumentvorlageListTitel'),
          requiresAuth: true,
        },
        component: () => import(`@/views/ModulManagement/DokumentvorlagengruppeListView.vue`),
      },
      {
        path: 'moduladmin/po-list/:poId/modul-list/:id/',
        meta: {
          name: 'Moduldetail',
          title: i18n.global.t('Common.ModulDetailTitle'),
          requiresAuth: true,
        },
        component: () =>
          import(`@/views/ModulManagement/ModulDetailView.vue`),
        props: true,
      },
      {
        path: '/403',
        meta: {
          name: 'PermissionDenied',
          requiresAuth: true,
          requiresRole: false,
        },
        component: () => import(`@/components/error/PermissionDenied.vue`),
      },
      {
        path: '/500',
        meta: {
          name: 'ServerError',
          requiresAuth: true,
          requiresRole: false,
        },
        component: () => import(`@/components/error/Error.vue`),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
    meta: {
      requiresAuth: true,
      requiresRole: false,
    },
  },
  // Error component fallback
  {
    path: '/:catchAll(.*)',
    component: () => import(`@/components/error/NotFound.vue`),
    name: 'NotFound',
  },
]
